.App {
  text-align: center;
  background-color: #000000;
  color: white;
  font-family: sans-serif;
  margin: 0.75vh;
  padding: 0;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
}

.bingo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button.btn-bingo {
  width: 5.5vw;
  height: 5.5vw;
  font-size: 3.25vw;
  font-weight: bold;
  text-align: center;
}

button.btn-bingo:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.App-link {
  color: #61dafb;
}



body {
  background-color: #000000 !important;
  color: white;
  font-family: sans-serif;
  padding: 0;
}

h1#ritual-title {
  margin-top: 5px;
  margin-bottom: 2.5vw;
  font-size: 2.5vw;
  font-family: 'Playfair Display', serif;
}


